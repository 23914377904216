import { darken } from "@theme-ui/color";

const prism = {
  ".prolog": { color: darken("text", 0.55) },
  ".comment": { color: "gray", fontStyle: "italic" },
  ".builtin,.changed,.keyword,.property-access,.property": { color: "class" },
  ".number,.inserted": { color: "char" },
  ".constant": { color: darken("text", 0.55) },
  ".attr-name,.variable": { color: darken("text", 0.55) },
  ".selector": { color: darken("text", 0.55) },
  ".tag": { color: "tag" },
  ".punctuation,.operator": { color: "highlighted" },
  ".punctuation": { color: "plain" },
  ".function": { color: "param" },
  ".class-name": { color: "accent" },
  ".char,.deleted,.string,.attr-value": { color: "char" },
  ".key,.control-flow": { color: "highlighted" },
  ".plain": { color: "plain" },
};
//darken('accent', 0.55)
export default prism;
