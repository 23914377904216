import prism from "./prism";

const theme = {
  config: {
    initialColorModeName: "light",
  },
  colors: {
    modes: {
      light: {
        text: "#212529",
        background: "#f8f9fa",
        primary: "#f6f6f6",
        secondary: "#f8f9fa",
        highlighted: "#f6f6f6",
        accent: "#fff",
        gray: " #423F3D",
        class: "#C4C4C4",
        tag: "#ffffff",
        param: "#C4C4C4",
        codeback: "#000000",
        plain: "#5c5856",
        char: "#8C8C8C",
      },
      dark: {
        text: "#f8f9fa",
        background: "#161616",
        primary: "#393939",
        secondary: "#2f2f2f",
        highlighted: "#d7d5d4",
        accent: "#bcb9b7",
        gray: " #423F3D",
        class: "#C4C4C4",
        tag: "#ffffff",
        param: "#C4C4C4",
        codeback: "#000000",
        plain: "#5c5856",
        char: "#8C8C8C",
      },
      deep: {
        text: "#E0CEED",
        background: "hsl(230, 25%, 18%)",
        primary: "hsl(260, 100%, 80%)",
        secondary: "#1C202F",
        secondary2: "hsl(290, 100%, 80%)",
        highlighted: "hsl(260, 20%, 40%)",
        accent: "hsl(290, 100%, 80%)",
        gray: "#614e6e",
        class: "#B657FF",
        tag: "#A29DFA",
        param: "#9076a1",
        codeback: "#29222E",
        char: "hsl(290, 100%, 80%)",
        plain: "#E0CEED",
      },
      blue: {
        text: "#1A5B8E",
        background: "#EAF3FB",
        primary: "#8EC2EA",
        secondary: "#CFE5F6",
        highlighted: "#B4D7F1",
        accent: "#4A6A97",
        gray: "#aec2e0",
        class: "#4b96d2",
        tag: "#164C76",
        param: "#5b96c5",
        codeback: "#eff6fc",
        char: "#62c0ee",
      },
      chipmunkpink: {
        text: "#F73324",
        background: "#fedddb",
        primary: "#FF8582",
        secondary: "#ffd2d1",
        highlighted: "#FFB2B1",
        accent: "#F9584B",
        gray: "#e0a8a3", //e0a8a3  e3aeab
        class: "#d2504b", // d2504b //e5342e  fa2119
        tag: "#73100d", //73100d  7d0703
        param: "#c55d59", //c55d59  e23832
        codeback: "#fee0df",
        char: "#ff5747", //fc1d1d  ff5747
      },
      orangejulius: {
        text: "#F76E0D",
        background: "#fdcaa5",
        primary: "#FED8AD",
        secondary: "#Fdbd8f",
        highlighted: "#FCAB73",
        accent: "#FAA263",
        accent2: "#F98432",
        gray: "#cfa27d",
        class: "#d2864b", //d2864b ce610d
        tag: "#883a02", //883a02 461e01
        param: "#c78d60", //c78d60 ea9d62
        codeback: "#fdcdaa",
        char: "#fb940e", //fc7a1d  ff730f fb940e
      },
    },
  },
  fonts: {
    body: "'Noto Sans JP', sans-serif",
    secondary: "Menlo, monospace",
  },
  fontWeights: {
    normal: 100,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    normal: 1.5,
    medium: 1.4,
  },
  fontSizes: {
    xs: 12,
    sm: 16,
    smd: 20,
    md: 32,
    lg: 48,
    xl: 64,
  },
  letterSpacings: {
    normal: "0",
    wide: "0.25em",
  },
  space: [0, 8, 16, 32, 64],
  // breakpoints: ["900px", "1290px"],
  breakpoints: ["40em", "52em", "64em", "80em"],
  forms: {
    label: {
      p: ".1rem",
      px: ".3rem",
      color: "text",
      fontSize: "sm",
      fontWeight: "bold",
    },
    input: {
      borderRadius: "10px",
      "&:focus": {
        borderColor: "accent",
        boxShadow: (t) => `0 0 0 1px ${t.colors.accent}`,
        outline: "none",
      },
    },
    textarea: {
      borderRadius: "10px",
      "&:focus": {
        borderColor: "accent",
        boxShadow: (t) => `0 0 0 1px ${t.colors.accent}`,
        outline: "none",
      },
    },
  },
  styles: {
    html: {
      height: "100%",
      minHeight: "100vh",
    },
    body: {
      height: "100%",
      minHeight: "100vh",
    },
    root: {
      height: "100%",
      minHeight: "100vh",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    p: {
      fontWeight: "medium",
      fontSizes: "xs",
    },
    GatsbyImage: {
      zIndex: "0",
    },
    pre: {
      ...prism,
      fontFamily: "Menlo, monospace",
      lineHeight: 1.5,
      fontSize: [".9rem", "1rem"],
      fontWeight: 600,
      borderRadius: ".8rem",
      padding: [".6rem", "1rem", "1rem", "2rem"],
      backgroundColor: "codeback",
      overflowY: "hidden",
      m: ["-.5rem", "0rem", "0rem", "0rem"],
      my: "2rem",
    },
    inlineCode: {
      fontFamily: "monospace",
      bg: "secondary",
      p: ".3rem",
      m: ".4rem",
      borderRadius: "4px",
      fontWeight: "medium",
      fontSizes: "lg",
    },
    a: {
      color: "text",
      "&:hover": {
        color: "char",
      },
    },
  },
  layout: {
    primary: {
      flex: 1,
      width: ["95%", "80%", "70%", "60%"],
      margin: "auto",
      textAlign: "left",
    },
    secondary: {
      flex: 1,
      width: ["90%", "80%", "70%", "60%"],
      margin: "auto",
      textAlign: "left",
    },
  },
  iconButton: {
    primary: {
      margin: "auto",
      padding: ".6rem",
      width: "100%",
      height: "100%",
      minWidth: "3rem",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "highlighted",
      },
    },
    primaryLarge: {
      margin: "auto",
      p: "1rem",
      width: "4.5rem",
      height: "4.5rem",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "highlighted",
      },
    },
    secondary: {
      margin: "auto",
      p: ".6rem",
      width: "2.8rem",
      height: "2.8rem",
      color: "text",
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "accent",
      },
    },
  },
  icon: {
    primary: {
      width: "2rem",
      height: "2rem",
      minWidth: "2rem",
    },
    secondary: {
      margin: "auto",
      width: "2em",
      height: "2em",
      color: "secondary",
    },
  },
  images: {
    borderRadius: "10px",
  },
  styledLinks: {
    primary: {
      color: "inherit",
      m: "auto",
      px: "1rem",
      maxHeight: "3rem",
      height: "100%",
      width: "100%",
      borderRadius: "10px",
      textDecoration: "none",
      display: "table",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "highlighted",
      },
      "&.active": {
        backgroundColor: "highlighted",
      },
    },
    secondary: {
      p: "1em",
      margin: ".2em",
      marginLeft: ".5em",
      marginRight: "2em",
      color: "text",
      borderRadius: "10px",
      textDecoration: "none",
      display: "table",
      width: "100%",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "accent",
      },
      "&.active": {
        backgroundColor: "accent",
      },
    },
  },
  buttons: {
    primary: {
      color: "white",
      bg: "highlighted",
      "&:hover": {
        color: "highlighted",
        bg: "white",
      },
    },
    secondary: {
      border: "none",
      borderRadius: "10px",
      color: "text",
      height: "100%",
      px: ["1rem", "2em"],
      py: "1em",
      bg: "secondary",
      "&:hover": {
        color: "secondary",
        bg: "text",
      },
    },
  },
};

export default theme